
import image from '~/assets/img/microwaver-error.png';
export default {
  layout: 'error', // you can set a custom layout for the error page
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      image,
    };
  },
};
